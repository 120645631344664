import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './InitialPage.module.scss'
import { TModalOpen } from '../../utils/tupes'
import { Offers } from '../Offers/Offers'

const titleAnimation = {
    hidden: {
        x: -800,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

const boxAnimation = {
    hidden: {
        opacity: 0,
    },

    visible: {
        opacity: 1,
        transition: {ease: "easeOut", duration: 1 }
    }
}

export const InitialPage:FC<TModalOpen> = ({isOpen}) => {

    const makeMuted = (elt:any) => {
        if (elt) {
            elt.muted = true;
        }
        };

    return (
        <motion.section
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
                <div className={styles.video_box}>
                <video autoPlay loop muted playsInline  className={styles.video} ref={makeMuted}
                >
                    <source  src={require('./video.mp4')} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </div>
                <div className={styles.item}>
                    <div className={styles.overlay}>
                        <div className={styles.box}>
                            <motion.h2 className={styles.title} variants={titleAnimation}>Создаю уникальные продающие сайты для&nbsp;бизнеса</motion.h2>
                            <Offers/>
                            <motion.button className={styles.button} onClick={isOpen} variants={boxAnimation} type='button' aria-label='Обсудить проект'>получить бесплатную консультацию <span className={styles.glare}></span></motion.button>
                        </div>
                    </div>
                </div>    
        </motion.section>
    )
}