import React from 'react'
import styles from './Offers.module.scss'



export const Offers = () => {
    return (
        <ul className={styles.list}>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Качество веб-студии <span>по ценам фрилансера</span></p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Никаких шаблонов и cms <span>только ручной код</span></p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Логотип и домен в зонах <span>.ru&nbsp;или .рф в подарок</span></p>
            </li>
        </ul>
    )
}