import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './Procedure.module.scss'
import image_1 from '../../image/Procedure/image_1.svg'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Procedure:FC = () => {
    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}} 
            >
                <motion.h1 className={styles.title} variants={titleAnimation}><span>Этапы</span> cоздания сайта</motion.h1>
                <motion.p className={styles.text} variants={textAnimation}>Четыре простых шага при создании продающего и функционального сайта</motion.p>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_container}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={image_1} alt='этап'/>
                                    <p className={styles.number}>01</p>
                                </div>    
                                <h3 className={styles.item_title}>Знакомство</h3>
                                <p className={styles.item_text}>Расскажите подробно о своих задачах и целях. Каким бы Вы хотели видеть будущий сайт(основные блоки, функционал). Далее, мы обсуждаем детали проекта и его стоимость. Вы вносите оговоренную предоплату.</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_container}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={image_1} alt='этап'/>
                                        <p className={styles.number}>02</p>
                                    </div>
                                <h3 className={styles.item_title}>Согласование</h3>
                                <p className={styles.item_text}>Создаю первые три блока сайта, уточняю предпочтения по дизайну, стиль и функционалу. Согласовываю с Вами детали, при необходимости вношу поправки.  После того как Вы одобряете проект, я приступаю к дальнейшей разработке.</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_container}>
                                    <div className={styles.item_box}>
                                        <img className={styles.icon} src={image_1} alt='этап'/>
                                        <p className={styles.number}>03</p>
                                    </div>
                                <h3 className={styles.item_title}>Создание</h3>
                                <p className={styles.item_text}>Верстаю и програмирую сайт, делаю SEO оптимизацию, регистрирую сайт в Яндекс и Google, устанавливаю счетчики аналитики и прописываю цели.  Ваш сайт можно будет в любой момент расширить до любого функционала, от одностаничника до полноценного магазина.</p>
                            </div>
                            
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_container}>
                                <div className={styles.item_box}>
                                    <img className={styles.icon} src={image_1} alt='этап'/>
                                    <p className={styles.number}>04</p>
                                </div>
                                <h3 className={styles.item_title}>Передача</h3>
                                <p className={styles.item_text}>После того как Вы примете  проект, Вы оплачиваете остаток оговоренной суммы и я передаю его Вам.  Переношу сайт на Ваш хостинг или оставляю на своём (на Ваше усмотрение) и беру на обслуживание (при необходимости).</p>
                            </div>
                    </motion.li>
                </ul>
        </motion.section>
    )
}