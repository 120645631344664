import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../WebsitePresentation/WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_25.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'
import i from '../../image/Chestnost/i.png'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const LandingPage:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main} 
            id='prices'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}           
            >
            <BreadCrumbs
                text={'Landing Page'}
            />
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Создание лендинга <span>Landing&#160;Page</span></motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>25 000₽</span> от <span>5</span> дней</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>Продающий лендинг- самый востребованный продукт. Благодаря продуманным маркетингом и триггерами Вы будете получать целевые заявки. Сайт раскрывает ваш продукт или услугу, давая понять почему клент должен купить у вас а не у конкурента. В моих продающих лендингах все действия направлены на то чтобы получить максимально ТЁПЛЫЕ заявки по минимальной стоимости. Никаких шаблонов — разработаю авторский продающий лендинг с нуля.</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                    <div className={styles.podskazka}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_one}>
                                <p className={styles.podskazka_text}>Никаких конструкторов - только ручная валидная верстка</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_one}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text}>
                                <p className={styles.podskazka_text}>Авторский продающий WOW-дизайн</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_two}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_two}>
                                <p className={styles.podskazka_text}>Смысловая маркетинговая упаковка вашей компании и продукта в одностраничный продающий сайт</p>
                            </div>
                        </span>
				    </div>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Что такое <span>Landing&#160;Page</span></h2>
                <p className={styles.text}>Лендинг (или landing) – это посадочная страница, которая должна мотивировать посетителя купить товар или услугу.</p>
                <p className={styles.text}>Технически это просто сайт, состоящий из одной страницы с необходимым минимум информации. Задача лендинга – побыстрее завлечь и убедить, склонить зашедшего на страницу человека к покупке или заказу услуг.</p>
                <p className={styles.text}>Как люди попадают на лендинги? Обычно с другого сайта – из поисковика, какого-то партнерского сайта или другого сайта с рекламой. На лендинг может вести рекламный баннер или ссылка из социальной сети (скажем, твиттера, инстаграма).</p>
                <p className={styles.text}>Обычно лендинг не рассчитан на широкий ассортимент – доказано, что чем шире выбор, тем сложнее человеку принять решение, поэтому на лендинге обычно представлена пара-тройка возможных вариаций (а то и вообще один вариант).</p>
                <p className={styles.text}>Лендинг – это собрание всей необходимой для продажи информации, ее концентрация, и в то же время лендинг – это яркая обертка, в которую вам нужно завернуть ваш продукт, чтобы он стал привлекательным для конечного пользователя.</p>
                </motion.div>
                <Advantages/>
                <motion.div 
                    className={styles.box_info}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.1, once: true}}
                    >
                <motion.h3 className={styles.title} variants={textAnimation}>Из чего состоит <span>Landing&#160;Page</span></motion.h3>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Призыв к действию</h4>
                        <p className={styles.item_text}>Кнопки с призывом к действию, которые побуждают пользователей совершить желаемое действие - заказать, позвонить, заполнить форму. Кнопка привлекает гораздо больше внимания, чем просто надпись. Поэтому призыв к действию в виде кнопки будет более заметен и эффективен.</p>
                    </motion.li>
                    <motion.li className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Эффективный заголовок</h4>
                        <p className={styles.item_text}>Хороший заголовок – важная составляющая успеха вашей лендинг-страницы. Заголовок должен заинтересовать и заставить пользователя провести на странице как можно больше времени (и выполнить целевое действие).</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Простая структура</h4>
                        <p className={styles.item_text}>Так как лендинг это в общем понимании даже не сайт, а просто одна страница, то лучше его делать максимально простым и понятным. Если вы перегрузите лендинг, то пользователь может запутаться и не совершить желаемое действие.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контроль взгляда</h4>
                        <p className={styles.item_text}>Следите за взглядом посетителя. Прикиньте, как посетитель окажется на вашей странице, куда он посмотрит и что захочет сделать. Главный вопрос на этом этапе: ведет ли все к выполнению целевого действия?</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Доверие</h4>
                        <p className={styles.item_text}>Выполнит ли посетитель целевое действие или нет – это может также зависеть от доверия, которое у него вызовет лендинг. Подумайте, какие вопросы крутились бы у вас в голове, окажись вы на таком сайте. Какие сомнения может вызвать материал, и как можно эти сомнения рассеять.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Определение срока</h4>
                        <p className={styles.item_text}>Если посетитель почувствует, что скидка на товар, бесплатная доставка или сам товар скоро закончатся, то это может заставить его быстрее сделать свой выбор (и выполнить целевое действие).</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контакты</h4>
                        <p className={styles.item_text}>Предоставьте Вашим потенциальным клиентом исчерпывающую информацию о Ваших контактных данных: укажите адрес, номер телефона, электронную почту, ссылки на социальные сети.</p>
                    </motion.li>
                </ul>
                </motion.div>
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}

