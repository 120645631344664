import React, {FC} from 'react'
import styles from './SitesCard.module.scss'
import { motion } from "framer-motion"
import { TSitesCard } from '../../utils/tupes'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

export const SitesCard:FC<TSitesCard> = ({image, title, link}) => {

    return (
        <motion.li 
            className={styles.item}
            variants={cardAnimation}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <a className={styles.link} target='_blank' rel='noopener noreferrer' href={link}>
                <figure className={styles.article}>
                    <img className={styles.image} src={image} alt='сайт'/>
                    <figcaption className={styles.box}>
                        <p className={styles.subtitle}>Посмотреть проект</p>
                    </figcaption>
                </figure>
            </a>
        </motion.li>
    )
}