import React, {FC} from 'react'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom'
import styles from './Info.module.scss'
import { TInfo } from '../../utils/tupes'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}


export const Info:FC<TInfo> = ({title, text, image, title_button, link}) => {
    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}} 
            >
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h3 className={styles.title} variants={titleAnimation}>{title}</motion.h3>
                    <motion.p className={styles.text} variants={subtitleAnimation}>{text}</motion.p>
                    <Link className={styles.link} to={link}>{title_button} <span className={styles.glare}></span></Link>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image} alt='макеты сайтов'/>
                </motion.div>
            </div>
        </motion.section>
    )
}