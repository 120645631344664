import React, {FC, useEffect} from 'react'
import styles from './Works.module.scss'
import { SitesList } from '../../components/SitesList/SitesList'
import { Technologies } from '../../components/Technologies/Technologies'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import { TMenu } from '../../utils/tupes'

export const Works:FC<TMenu> = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <BreadCrumbs
                text={'Работы'}
            />
            <SliderTechnologies/>
            <SitesList/>
            <Technologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </section>
    )
}