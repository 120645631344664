import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './Advantages.module.scss'
import img_1 from '../../image/Advantages/img_1.svg'
import img_2 from '../../image/Advantages/img_2.svg'
import img_3 from '../../image/Advantages/img_3.svg'
import img_4 from '../../image/Advantages/img_4.svg'
import img_5 from '../../image/Advantages/img_5.svg'
import img_6 from '../../image/Advantages/img_6.svg'
import img_7 from '../../image/Advantages/img_7.svg'
import img_8 from '../../image/Advantages/img_8.svg'
import img_9 from '../../image/Advantages/img_9.svg'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const Advantages:FC = () => {
    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}} 
            >
            <div className={styles.container}>
                <motion.h2 className={styles.title} variants={titleAnimation}><span>Моя цель -</span> заявки с&#160;вашего&#160;сайта</motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Привожу только целевых посетителей. Ориентируюсь на данные конверсий, а&#160;не&#160;на&#160;объем&#160;трафика.</motion.p>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_1} alt='мишень'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Цепляющий визуал</p>
                            <p className={styles.item_text}>Размещаю на страницах сайта только качественно обработанные фото и видеоролики. Визуальное наполнение сайта покажет товары и услуги с лучшей стороны.</p>
                        </div>
                    </motion.li>
                    <motion.li className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                    >
                        <img className={styles.item_image} src={img_2} alt='люди'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Целевая аудитория</p>
                            <p className={styles.item_text}>Всегда ориентируюсь на интересы, вкусы и предпочтения ваших потенциальных клиентов, чтобы приковать внимание к вашему сайту.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_3} alt='люди'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Всегда в тренде</p>
                            <p className={styles.item_text}>Дизайн, структура, контент, формы обратной связи — везде я использую решения, основанные на актуальных тенденциях индустрии веб-разработки.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_4} alt='люди'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Индивидуальный контент</p>
                            <p className={styles.item_text}>Я создаю каждый сайт исходя из видения клиента и особенностей его бизнеса, подбирая только уникальные фото, видео и тексты, которые будут привлекать клиентов.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_5} alt='люди'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Продающие скрипты</p>
                            <p className={styles.item_text}>Я хорошо знаю как  и что писать, чтобы тексты привлекали и продавали. Ваш сайт будет конвертировать входящий трафик в клиентов.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.item_image} src={img_6} alt='люди'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Лаконичные и понятные</p>
                            <p className={styles.item_text}>Я не перегружаю страницы сайтов лишней и бесполезной информацией, чтобы они получались простыми, понятными и удобными для пользователя.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_7} alt='мишень'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Техника «FACE TO FACE»</p>
                            <p className={styles.item_text}>В основе каждого сайта, который я разрабатываю, лежит интернет-маркетинг и удобство взаимодействия с ресурсом дя пользователя.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_8} alt='мишень'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Заказ в пару кликов</p>
                            <p className={styles.item_text}>Клиентам будет легко заказывать ваши товары и услуги. Процесс оформления заявки сведется к паре простых действий, что улучшит конверсии.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <img className={styles.item_image} src={img_9} alt='мишень'/>
                        <div className={styles.box_info}>
                            <p className={styles.item_subtitle}>Функциональность</p>
                            <p className={styles.item_text}>Делаю простую и понятную систему внутреннего управления. Настраиваю фильтры товаров и услуг. Обеспечиваю работу форм обратной связи.</p>
                        </div>
                    </motion.li>
                </ul>
            </div>
        </motion.section>
    )
}