import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import modal from './Modal.module.scss'
import {ModalOverlay} from '../ModalOverlay/ModalOverlay'

export const Modal = ({onClose}: any) => {

    const modalRoot = document.getElementById("modals") as HTMLElement;
    

    useEffect(() => {
        const handleEscClose = (evt: KeyboardEvent) =>{
            if (evt.key ==='Escape')  onClose();
        }
        document.addEventListener('keydown', handleEscClose);
        return () => document.removeEventListener('keydown', handleEscClose);
    }, [onClose]);

    return createPortal (
        <ModalOverlay onClick={onClose}>
            <div className={modal.modal} onClick={evt => evt.stopPropagation()}>
                <div className={modal.overlay}>
                    <h2 className={modal.title}>Спасибо за обращение</h2>
                    <p className={modal.text}>я свяжусь с вами в ближайшее время</p>
                    <button className={modal.button} type='button' onClick={onClose}>Вернуться на сайт <span className={modal.glare}></span></button>
                </div>    
            </div>
        </ModalOverlay>
        ,modalRoot
    );
};