import React, {FC} from 'react';
import styles from './ModalOverlay.module.scss';
import {TModalOverlay} from '../../utils/tupes'

export const ModalOverlay:FC<TModalOverlay> = ({onClick, children}) => {

    return(
        <section  className={styles.popup} onClick={onClick} >
            {children}
        </section>
    
    );
};