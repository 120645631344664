import React, {FC, useState, useEffect, useCallback} from 'react'
import { useLocation } from 'react-router-dom'; 
import { motion } from "framer-motion"
import styles from './SitesList.module.scss'
import { arrSites } from '../../utils/data'
import { SitesCard } from '../SitesCard/SitesCard'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'

export const SitesList:FC = () => {

    const routes  = useLocation(); 
    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)



    useEffect(() => {
        const getCards = () => {
            if (width >= 960) {
                setMoviesTotal(15);
                setAddMovies(3);
                } else if (width < 980 && width >= 629) {
                    setMoviesTotal(10);
                    setAddMovies(2);
                        } else if (width <= 629) {
                            setMoviesTotal(8);
                            setAddMovies(1);
            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])


    const titleAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const textAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5}
        } 
    }

    const cardAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .7}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}} 
            >
            <div className={styles.container}>
                {routes.pathname === '/' ?
                (<motion.h2 className={styles.title} variants={titleAnimation}>Портфолио <span>моих&#160;работ</span></motion.h2>) :
                (<motion.h1 className={styles.title} variants={titleAnimation}>Портфолио <span>моих&#160;работ</span></motion.h1>)}
                <motion.p className={styles.text} variants={textAnimation}>Мое портфолио-не просто сайты. За каждым целая история, которую картинкой или ссылкой не покажешь.</motion.p>    
                <motion.ul className={styles.list} variants={textAnimation}>
                    {arrSites.map((item, index) => {
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <SitesCard
                                image={item.image}
                                title={item.title}
                                link={item.link}
                                key={keyUid}
                        />
                            ) 
                        }else {
                            return ''
                        }
                    })}         
                </motion.ul>
                {moviesTotal < arrSites.length && <motion.button  className={styles.button} onClick={addCards} variants={cardAnimation}>Ещё работы <span className={styles.glare}></span></motion.button>}
            </div>
        </motion.section>    
    )
}