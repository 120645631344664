import React from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss';
import logo from '../../image/Header/logo.webp'

export const MenuMobile = ({onClose, isMenuMobile}: any) => {

    return (
        <div className={`${isMenuMobile === true ? styles.main : styles.main_open}`}>
            <div className={styles.box}>
                <img className={styles.logo} src={logo} alt = 'логотип'/>
                <button className={styles.button} onClick={onClose} aria-label='закрыть меню'/>
            </div>
            <nav className={styles.nav}>
                    <Link className={styles.menu_link} to='/' onClick={onClose}>Главная</Link>
                    <Link className={styles.menu_link} to='/portfolio' onClick={onClose}>Примеры работ</Link>
                    <Link className={styles.menu_link} to='/uslugi' onClick={onClose}>Мои услуги</Link>
                    <Link className={styles.menu_link} to='voprosy' onClick={onClose}>FAQ</Link>
                    <Link className={styles.menu_link} to='/kontakty' onClick={onClose}>Контакты</Link>
                </nav>
        </div>
    );
}
