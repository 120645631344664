import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import styles from './Questions.module.scss'
import { Hint } from '../../components/Hint/Hint'
import { arrQuestions } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { TMenu } from '../../utils/tupes'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const textAnimation = {
    hidden: {
        y: 300,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .6}
    } 
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const Questions:FC<TMenu> = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.2, once: true}} 
            >
                <BreadCrumbs
                    text={'Вопросы'}
                />  
                    <div className={styles.box}>
                        <div className={styles.container}>
                            <motion.h1 className={styles.title} variants={titleAnimation}><span>Часто</span> задаваемые вопросы</motion.h1>
                            <motion.p className={styles.text} variants={textAnimation}>Ниже приведен список наиболее часто задаваемых вопросов.</motion.p>
                            <motion.p className={styles.text} variants={textAnimation}>Если у Вас остался вопрос или нужны уточнения, смело спрашивайте у меня, отвечаю почти мгновенно.</motion.p>
                            <motion.ul className={styles.list} variants={boxAnimation}>
                                    {arrQuestions.map(item  => {
                                        const keyUid = uuidv4();
                                        return(
                                            <Hint
                                                question={item.question}
                                                answer={item.answer}
                                                key={keyUid}
                                        />
                                        )
                                    }   
                                        )}
                            </motion.ul>
                        </div>
                    </div>
                    <Info
                        title={ arrInfo[1].title}
                        text={ arrInfo[1].text}
                        image={ arrInfo[1].image}
                        title_button={arrInfo[1].title_button}
                        link={arrInfo[1].link}
                    />
                    <Callback
                        popupOpen={popupOpen}
                    />
        </motion.section>
        
    )
}