import React, { FC, useState } from 'react';
import styles from './Hint.module.scss'
import active from '../../image/Hint/active.svg'
import disactive from '../../image/Hint/disactive.svg'
import { TQuestion } from '../../utils/tupes'

export  const Hint:FC<TQuestion> = ({question, answer}) => {

    const [isActive, setIsActive] = useState(false);


    return (
        <li className={styles.hint}>
            <div className={styles.box} onClick={() => setIsActive(!isActive)}>
                <h3 className={styles.title}>{question}</h3>
                {!isActive ? 
                (<img className={styles.image} src={active} alt='плюс'  onClick={() => setIsActive(!isActive)}/>) :
                (<img className={styles.image} src={disactive} alt='минус' onClick={() => setIsActive(!isActive)}/>)
                }
            </div>
            {isActive && (<p className={styles.text}>{answer}</p>)}
        </li>
    )
}