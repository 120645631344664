import React, {FC, useEffect} from 'react'
import {Helmet} from "react-helmet"
import { motion } from "framer-motion"
import styles from './MyServices.module.scss'
import { Technologies } from '../../components/Technologies/Technologies'
import { Prices } from '../../components/Prices/Prices'
import { Callback } from '../../components/Callback/Callback'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TMenu } from '../../utils/tupes'
import { SliderAdvantages } from '../../components/SliderAdvantages/SliderAdvantages'
import { Procedure } from '../../components/Procedure/Procedure'
import { Advantages } from '../../components/Advantages/Advantages'
import { InitialPageServices } from '../../components/InitialPageServices/InitialPageServices'

export const MyServices:FC<TMenu> = ({popupOpen, isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <Helmet>
                <title>Создание уникальных продающих сайтов Тихонов Дмитрий</title>
                <meta name="description" content="Создание продающих сайтов под ключ Мытищи, Королев, Балашиха  разработка персональных сайтов, корпоративных сайтов, разработка лендингов, продвижение сайтов" />
                <meta property="og:title" content="Создание уникальных продающих сайтов Тихонов Дмитрий"/>
                <meta property="og:description" content="Создание продающих сайтов под ключ Мытищи, Королев, Балашиха разработка персональных сайтов, корпоративных сайтов, разработка лендингов, продвижение сайтов"/>
                <link rel="canonical" href="https://tihonov-studio.ru/uslugi" />
            </Helmet>
            <InitialPageServices
                isOpen={isOpen}
            />
            <BreadCrumbs
                text={'Услуги'}
            />
            <SliderAdvantages/>    
            <Prices/>
            <Advantages/>
            <Procedure/>
            <Technologies/>
            <Callback
                popupOpen={popupOpen}
            />
        </motion.section>
    )
}