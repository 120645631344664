import React, {FC} from 'react'
import { motion } from "framer-motion"
import { arrTechnologies } from '../../utils/data'
import styles from './Technologies.module.scss'
import { v4 as uuidv4 } from 'uuid'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

export const Technologies:FC = () => {

    return (
            <motion.div 
                className={styles.container}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.2, once: true}}
                >
                <motion.h3 className={styles.title} variants={titleAnimation}>Используемые <span>технологии</span></motion.h3>
                <ul className={styles.list}>
                    {arrTechnologies.map(item => {
                        const keyUid = uuidv4()
                        return(
                            <motion.li 
                                className={styles.item_icon} 
                                key={keyUid}
                                variants={cardAnimation}
                                initial='hidden'
                                whileInView='visible'
                                viewport={{amount: 0.05, once: true}}
                                >
                                <img className={styles.icon} src={item.image} alt='лого'/>
                            </motion.li>
                        )
                    })}
                </ul>
            </motion.div>
    )
}