import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../WebsitePresentation/WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_1.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

export const TechnicalSupport:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <BreadCrumbs
                text={'Поддержка сайта'}
            />
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Поддержка сайта</motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>2 500₽</span> / <span>1</span> час</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>В нынешних реалиях очень важно поддерживать сайт в «живом» состоянии, иначе он рискует сгинуть в недрах поисковой выдачи</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Техническая <span>поддержка&#160;сайта</span></h2>
                <p className={styles.text}>Работа над сайтом не заканчивается со сдачей его от разработчика в Ваши руки. Состояние любого сайта необходимо периодически проверять и мониторить на наличие ошибок, а также исправлять обнаруженные неточности и вносить корректировки. Сайт — это сложная структура, которой требуется качественная поддержка.</p>
                <p className={styles.text}>Закажите услугу технической поддержки сайта, и  я буду обеспечивать его стабильное функционирование и вносить необходимые Вам изменения в контент.</p>
            </motion.div>
            <Advantages/>
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}